var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    _vm._l(_vm.choices, function (c) {
      return _c(
        "div",
        { key: c.label, staticClass: "pa-0 mx-0" },
        [
          _c("v-checkbox", {
            staticClass: "ma-0",
            attrs: {
              label: c.label,
              "hide-details": "",
              "error-messages": _vm.errors,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
              change: _vm.onChange,
              input: _vm.onChange,
            },
            model: {
              value: c.value,
              callback: function ($$v) {
                _vm.$set(c, "value", $$v)
              },
              expression: "c.value",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }