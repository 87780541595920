var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("validation-observer", {
    ref: "addTicketAreasForm",
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({ valid }) {
          return [
            _c(
              "v-card",
              {
                staticClass: "m-2",
                staticStyle: { "background-color": "#f1f2f1" },
              },
              [
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "v-toolbar",
                      {
                        ref: "toolbar",
                        staticClass: "elevation-0",
                        attrs: { dark: "", color: "primary" },
                      },
                      [
                        _c("v-toolbar-title", [
                          _vm._v("Add Ticket Assignment Area"),
                        ]),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", dark: "" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("add-ticket-area-form-close")
                              },
                            },
                          },
                          [_c("v-icon", [_vm._v("mdi-close")])],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-card",
                      {
                        staticClass: "m-3 elevation-0",
                        staticStyle: { "background-color": "#f1f2f1" },
                      },
                      [
                        _c(
                          "v-card-text",
                          {
                            style: {
                              height: _vm.$vuetify.breakpoint.xsOnly
                                ? `${_vm.contentHeight}px`
                                : "auto",
                              "overflow-y": "auto",
                              "max-height": _vm.$vuetify.breakpoint.xsOnly
                                ? undefined
                                : "60vh",
                            },
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticStyle: { "background-color": "#fafafa" },
                              },
                              [
                                _c(
                                  "v-card-text",
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Region Name",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: "Region Name",
                                                    "hide-details": "auto",
                                                    "error-messages": errors,
                                                    success: valid,
                                                    color: "primary",
                                                  },
                                                  model: {
                                                    value: _vm.regionName,
                                                    callback: function ($$v) {
                                                      _vm.regionName = $$v
                                                    },
                                                    expression: "regionName",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Assigned To",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c("v-select", {
                                                  staticClass: "mt-4",
                                                  attrs: {
                                                    autocomplete: "off",
                                                    label: "Assigned To",
                                                    items: _vm.users,
                                                    "item-text":
                                                      _vm.getUserName,
                                                    "item-value": "user_id",
                                                    "error-messages": errors,
                                                    success: valid,
                                                    color: "primary",
                                                    "hide-details": "auto",
                                                  },
                                                  model: {
                                                    value: _vm.userId,
                                                    callback: function ($$v) {
                                                      _vm.userId = $$v
                                                    },
                                                    expression: "userId",
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c(
                                      "p",
                                      { staticClass: "caption pt-5 my-0" },
                                      [
                                        _vm._v(
                                          " Upload GeoJSON of Assignment Area "
                                        ),
                                      ]
                                    ),
                                    _c("file-pond", {
                                      ref: "file",
                                      attrs: {
                                        name: "file",
                                        "label-idle":
                                          "Upload GeoJSON file here...",
                                        "allow-multiple": false,
                                        files: _vm.geoJsonFile,
                                      },
                                      on: {
                                        addfile: _vm.onAddFile,
                                        removefile: _vm.onRemoveFile,
                                      },
                                    }),
                                    _c("validation-provider", {
                                      ref: "geoJsonInput",
                                      staticClass: "my-0 py-0",
                                      attrs: {
                                        name: "GeoJSON",
                                        rules: { required: true },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({
                                              errors,
                                              valid,
                                              pristine,
                                            }) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.geoJsonString,
                                                      expression:
                                                        "geoJsonString",
                                                    },
                                                  ],
                                                  staticStyle: {
                                                    display: "none",
                                                  },
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: _vm.geoJsonString,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.geoJsonString =
                                                        $event.target.value
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "caption my-0 py-0",
                                                    style: {
                                                      color:
                                                        valid || pristine
                                                          ? undefined
                                                          : "#ff5252",
                                                    },
                                                  },
                                                  [
                                                    !pristine &&
                                                    !valid &&
                                                    Array.isArray(errors)
                                                      ? [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                errors.join(
                                                                  ". "
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      : [_vm._v(" ")],
                                                  ],
                                                  2
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          color: "primary",
                          disabled:
                            !valid || _vm.geoJsonString === JSON.stringify({}),
                        },
                        on: { click: _vm.submit },
                      },
                      [_vm._v(" Save Ticket Area ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }