<template>
  <div class="d-flex justify-space-between gap">
    <v-btn
      fab
      small
      :dark="isSelected(d)"
      v-for="d of daysSettingsBeingEdited"
      :key="d.label"
      @click.stop="submitValue(d)"
      :color="isSelected(d) ? 'primary' : 'white'"
      class="pa-0"
    >
      {{ getInitial(d.label) }}
    </v-btn>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

const daysSettings = [
  { label: "sunday" },
  { label: "monday" },
  { label: "tuesday" },
  { label: "wednesday" },
  { label: "thursday" },
  { label: "friday" },
  { label: "saturday" },
];

export default {
  name: "DaysSelector",
  props: {
    value: {
      type: Array,
      default() {
        return daysSettings;
      },
    },
  },
  data() {
    return {
      daysSettingsBeingEdited: daysSettings,
    };
  },
  methods: {
    isSelected(daySetting) {
      return daySetting.value;
    },
    getInitial(day) {
      const [initial] = day;
      return initial.toUpperCase();
    },
    submitValue(daySetting) {
      this.daysSettingsBeingEdited = this.daysSettingsBeingEdited.map((s) => {
        if (daySetting.label === s.label) {
          return { ...s, value: !s.value };
        }
        return { ...s };
      });
      const daysSettingsBeingEdited = cloneDeep(this.daysSettingsBeingEdited);
      this.$emit("input", daysSettingsBeingEdited);
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (!Array.isArray(val)) {
          return;
        }
        this.daysSettingsBeingEdited = cloneDeep(val);
      },
    },
  },
};
</script>
