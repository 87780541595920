<template>
  <section>
    <section class="d-flex justify-space-between align-center py-1 flex-wrap">
      <section
        v-if="workflowSettingBeingEdited.trigger !== TRIGGER.TICKET_ASSIGNED"
      >
        <v-checkbox
          label="Assign Ticket"
          color="primary"
          hide-details
          class="py-1 my-0"
          v-model="workflowSettingBeingEdited.actions.assign_ticket.enabled"
          v-if="
            !workflowSettingBeingEdited.filter.locate_request_account_region_id
          "
          @change="onChange"
          @input="onChange"
          :disabled="!canManage811Codes"
        ></v-checkbox>
        <div v-else>
          <v-icon
            :disabled="
              Boolean(
                workflowSettingBeingEdited.filter
                  .locate_request_account_region_id
              )
            "
          >
            {{ mdiMinusBox }}
          </v-icon>
          <span class="ml-2 v-label theme--light">Assign Ticket</span>
        </div>
        <div
          v-if="
            workflowSettingBeingEdited.filter.locate_request_account_region_id
          "
          class="caption font-weight-medium ml-8 mt-n2"
        >
          Assignment already set based on area
        </div>
      </section>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Assigned To"
        rules="required"
        class="py-1"
        v-if="
          !workflowSettingBeingEdited.filter.locate_request_account_region_id &&
          workflowSettingBeingEdited.actions.assign_ticket.enabled &&
          workflowSettingBeingEdited.trigger !== TRIGGER.TICKET_ASSIGNED
        "
      >
        <v-autocomplete
          autocomplete="off"
          label="Assigned To"
          :error-messages="errors"
          :success="valid"
          :items="assignToUserChoices"
          item-value="value"
          item-text="label"
          color="primary"
          hide-details="auto"
          class="py-0 my-0 drop-down"
          v-model="
            workflowSettingBeingEdited.actions.assign_ticket.assign_to_user_id
          "
          :disabled="
            Boolean(
              workflowSettingBeingEdited.filter
                .locate_request_account_region_id || !canManage811Codes
            )
          "
          @change="onChange"
        ></v-autocomplete>
      </validation-provider>
    </section>
    <section class="d-flex justify-space-between align-center py-1 flex-wrap">
      <v-checkbox
        label="Send Email Notification"
        color="primary"
        hide-details
        class="py-1 my-0"
        v-model="workflowSettingBeingEdited.actions.send_email.enabled"
        @input="onChange"
        @change="onChange"
        :disabled="!canManage811Codes"
      ></v-checkbox>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Send To"
        rules="required"
        class="py-1"
        v-if="workflowSettingBeingEdited.actions.send_email.enabled"
      >
        <v-autocomplete
          autocomplete="off"
          label="Send To"
          :error-messages="errors"
          :success="valid"
          :items="sendToUserChoicesEmail"
          item-value="value"
          item-text="label"
          color="primary"
          hide-details="auto"
          class="py-0 my-0 drop-down"
          multiple
          v-model="workflowSettingBeingEdited.actions.send_email.email_user_ids"
          @change="onChange"
          :disabled="!canManage811Codes"
        ></v-autocomplete>
      </validation-provider>
    </section>

    <SimpleTemplateEditor
      v-if="workflowSettingBeingEdited.actions.send_email.enabled"
      class="my-2"
      v-model="workflowSettingBeingEdited.actions.send_email.message"
      @input="onChange"
      @rich-text-toggle-changed="
        enableRichTextEmail = $event;
        onChange();
      "
      :showToolbarButtons="enableRichTextEmail"
      :isPlainText="!enableRichTextEmail"
      :disabled="!canManage811Codes"
      showRichTextToggle
    />

    <section class="d-flex justify-space-between align-center flex-wrap py-1">
      <v-checkbox
        label="Send SMS Notification"
        color="primary"
        hide-details
        class="py-1 my-0"
        v-model="workflowSettingBeingEdited.actions.send_sms.enabled"
        @input="onChange"
        @change="onChange"
        :disabled="!canManage811Codes"
      ></v-checkbox>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Send To"
        rules="required"
        v-if="workflowSettingBeingEdited.actions.send_sms.enabled"
      >
        <v-autocomplete
          autocomplete="off"
          label="Send To"
          :error-messages="errors"
          :success="valid"
          :items="sendToUserChoicesSMS"
          item-value="value"
          item-text="label"
          color="primary"
          hide-details="auto"
          class="py-0 my-0 drop-down"
          multiple
          v-model="workflowSettingBeingEdited.actions.send_sms.sms_user_ids"
          @change="onChange"
          :disabled="!canManage811Codes"
        ></v-autocomplete>
      </validation-provider>
    </section>

    <SimpleTemplateEditor
      v-if="workflowSettingBeingEdited.actions.send_sms.enabled"
      class="my-2"
      v-model="workflowSettingBeingEdited.actions.send_sms.message"
      :showToolbarButtons="false"
      isPlainText
      @input="onChange"
      :disabled="!canManage811Codes"
    />

    <section class="d-flex justify-space-between align-center flex-wrap py-1">
      <v-checkbox
        label="Close Ticket"
        color="primary"
        hide-details
        class="py-1 my-0"
        v-model="workflowSettingBeingEdited.actions.close_ticket.enabled"
        @input="onChange"
        @change="onChange"
        :disabled="!canManage811Codes"
      ></v-checkbox>
      <div v-if="workflowSettingBeingEdited.actions.close_ticket.enabled">
        Closes all tickets that match the criteria above. It is strongly
        recommended that you send an email notification to someone who will
        review them on a regular basis and verify that the closed tickets are
        correct.
      </div>
    </section>

    <section
      class="d-flex flex-wrap justify-space-between align-center flex-wrap py-1"
      v-if="
        workflowSettingBeingEdited.trigger === TRIGGER.TICKET_RECEIVED &&
        cityworks811IntegrationEnabled
      "
    >
      <v-checkbox
        label="Update Service Request Priority"
        color="primary"
        hide-details
        class="py-1 my-0"
        v-model="
          workflowSettingBeingEdited.actions.set_service_request_priority
            .enabled
        "
        @input="onChange"
        @change="onChange"
        :disabled="!canManage811Codes"
      ></v-checkbox>

      <validation-provider
        v-slot="{ errors, valid }"
        name="Priority"
        rules="required"
        v-if="
          workflowSettingBeingEdited.actions.set_service_request_priority
            .enabled
        "
      >
        <v-select
          v-model="
            workflowSettingBeingEdited.actions.set_service_request_priority
              .priority
          "
          label="Priority"
          color="primary"
          :error-messages="errors"
          :success="valid"
          hide-details
          item-text="label"
          item-value="value"
          :items="serviceRequestPriorityChoices"
          :disabled="!canManage811Codes"
          :full-width="false"
          @input="onChange"
        ></v-select>
      </validation-provider>
    </section>
  </section>
</template>

<script>
import { cloneDeep } from "lodash";
import SimpleTemplateEditor from "@/components/shared/SimpleTemplateEditor";
import { mdiMinusBox } from "@mdi/js";
import permissionsMixin from "@/mixins/permissionsMixin";
import { isObject } from "lodash";
import { TRIGGER } from "@/constants/workflow";
import axios from "axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "DoTheFollowingForm",
  props: {
    value: Object,
    users: Array,
  },
  components: { SimpleTemplateEditor },
  computed: {
    cityworks811IntegrationEnabled() {
      return this.auth?.organization?.access_cityworks_811_integration;
    },
    serviceRequestPriorityChoices() {
      return this.serviceRequestPriorities.map((p) => {
        const { Code, Description } = p;
        return { label: Description, value: Code };
      });
    },
    sendToUserChoicesEmail() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter(
          (u) => u.is_active && !u.is_contact && u.receive_811_notifications
        )
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    sendToUserChoicesSMS() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter(
          (u) =>
            u.is_active &&
            !u.is_contact &&
            u.receive_811_notifications &&
            u.sms_notification
        )
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    assignToUserChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return this.users
        .filter((u) => u.is_active && !u.is_contact && u.locate_requests_user)
        .map((u) => {
          const { f_name: fname, l_name: lName, user_id: userId } = u;
          return { label: `${fname} ${lName}`, value: userId };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
  },
  data() {
    return {
      workflowSettingBeingEdited: {},
      mdiMinusBox,
      TRIGGER,
      serviceRequestPriorities: [],
      enableRichTextEmail: true,
      auth: {},
    };
  },
  mixins: [permissionsMixin],
  methods: {
    getAuthObject() {
      this.auth = JSON.parse(localStorage.getItem("auth"));
    },
    onChange() {
      if (!isObject(this.value.actions.send_email)) {
        this.workflowSettingBeingEdited.actions.send_email = {
          rich_text: true,
        };
      }

      this.workflowSettingBeingEdited.actions.send_email.rich_text =
        this.enableRichTextEmail;
      this.$emit("input", cloneDeep(this.workflowSettingBeingEdited));
    },
    async getServiceRequestPriorities() {
      const hasValidCityworksToken = await this.validateCityworksToken();
      if (!hasValidCityworksToken) {
        return;
      }
      const {
        data: { results },
      } = await axios.get(`${APIURL}/cityworks/service_request_priorities`);
      this.serviceRequestPriorities = results;
    },
    async validateCityworksToken() {
      const auth = JSON.parse(localStorage.getItem("auth")) ?? {};
      const { cityworks_token: cityworksToken } = auth ?? {};
      if (!cityworksToken) {
        return false;
      }

      try {
        const {
          data: { error },
        } = await axios.get(`${APIURL}/cityworks/validate_token`);
        return !error;
      } catch (error) {
        return false;
      }
    },
  },
  beforeMount() {
    this.getAuthObject();
    this.getServiceRequestPriorities();
    this.workflowSettingBeingEdited = cloneDeep(this.value);
    if (!isObject(this.value.actions.assign_ticket)) {
      this.workflowSettingBeingEdited.actions.assign_ticket = {};
    }

    if (!isObject(this.value.actions.send_email)) {
      this.workflowSettingBeingEdited.actions.send_email = {
        rich_text: true,
      };
    }

    if (!isObject(this.value.actions.close_ticket)) {
      this.workflowSettingBeingEdited.actions.close_ticket = {};
    }

    if (!isObject(this.value.actions.send_sms)) {
      this.workflowSettingBeingEdited.actions.send_sms = {};
    }

    if (!isObject(this.value.actions.set_service_request_priority)) {
      this.workflowSettingBeingEdited.actions.set_service_request_priority = {};
    }

    if (
      typeof this.workflowSettingBeingEdited.actions.send_email.rich_text ===
      "undefined"
    ) {
      this.workflowSettingBeingEdited.actions.send_email.rich_text = true;
    }
    this.enableRichTextEmail =
      this.workflowSettingBeingEdited.actions.send_email.rich_text;
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.workflowSettingBeingEdited = cloneDeep(this.value);
        if (!isObject(this.value.actions.assign_ticket)) {
          this.workflowSettingBeingEdited.actions.assign_ticket = {};
        }

        if (!isObject(this.value.actions.send_email)) {
          this.workflowSettingBeingEdited.actions.send_email = {
            rich_text: true,
          };
        }

        if (!isObject(this.value.actions.close_ticket)) {
          this.workflowSettingBeingEdited.actions.close_ticket = {};
        }

        if (!isObject(this.value.actions.send_sms)) {
          this.workflowSettingBeingEdited.actions.send_sms = {};
        }

        if (!isObject(this.value.actions.set_service_request_priority)) {
          this.workflowSettingBeingEdited.actions.set_service_request_priority =
            {};
        }
        if (
          typeof this.workflowSettingBeingEdited.actions.send_email
            .rich_text === "undefined"
        ) {
          this.workflowSettingBeingEdited.actions.send_email.rich_text = true;
        }
        this.enableRichTextEmail =
          this.workflowSettingBeingEdited.actions.send_email.rich_text;
      },
    },
  },
};
</script>

<style scoped>
.drop-down {
  width: 250px;
  max-width: 250px;
}
</style>
