<template>
  <section>
    <div class="pa-0 mx-0" v-for="c of choices" :key="c.label">
      <v-checkbox
        v-model="c.value"
        :label="c.label"
        hide-details
        class="ma-0"
        @click.stop
        :error-messages="errors"
        @change="onChange"
        @input="onChange"
      ></v-checkbox>
    </div>
  </section>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  name: "CheckboxGroup",
  props: {
    errors: Array,
    valid: Boolean,
    value: Array,
  },
  data() {
    return {
      choices: [],
    };
  },
  methods: {
    onChange() {
      this.$emit("input", cloneDeep(this.choices));
    },
  },
  beforeMount() {
    this.choices = cloneDeep(this.value);
  },
};
</script>
