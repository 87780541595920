<template>
  <section class="template-editor">
    <section
      v-if="editor"
      class="d-flex align-center flex-wrap border pa-2"
      :class="{
        'justify-space-between': showToolbarButtons,
        'justify-end': !showToolbarButtons,
      }"
      style="background-color: #f1f1f1"
    >
      <div
        class="d-flex flex-wrap align-center py-0 my-0"
        v-if="showToolbarButtons"
      >
        <div style="width: 150px" class="pa-0 ma-0">
          <v-select
            @change="onFontFamilyChange"
            :items="fontFamilyOptions"
            item-text="label"
            item-value="value"
            :value="fontFamilyValue"
            class="mr-2 py-0 my-0"
            hide-details
            :disabled="disabled"
          >
            <template #item="{ item: { label, value } }">
              <div
                class="py-0 my-0"
                :style="{
                  'font-family': value,
                }"
              >
                {{ label }}
              </div>
            </template>

            <template #selection="{ item: { label, value } }">
              <div
                class="py-0 my-0"
                :style="{
                  'font-family': value,
                }"
              >
                {{ label }}
              </div>
            </template>
          </v-select>
        </div>

        <v-btn
          icon
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          :disabled="disabled"
        >
          <v-icon>{{ mdiFormatBold }}</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          :disabled="disabled"
        >
          <v-icon>{{ mdiFormatItalic }}</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
          :disabled="disabled"
        >
          <v-icon>{{ mdiFormatUnderline }}</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <v-icon>{{ mdiFormatListBulleted }}</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          :disabled="disabled"
        >
          <v-icon>{{ mdiFormatListNumbered }}</v-icon>
        </v-btn>
        <v-menu
          v-model="showLinkMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :class="{ 'is-active': editor.isActive('link') }"
              @click="getLink()"
              :disabled="disabled"
            >
              <v-icon>{{ mdiLink }}</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="pt-2 pb-3 px-4">
              <validation-provider v-slot="{ errors, valid }" name="Input URL">
                <v-text-field
                  v-model="inputURL"
                  label="Input URL"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="primary"
                  name="name"
                  class="pt-1"
                  :disabled="disabled"
                />
              </validation-provider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="showLinkMenu = false"> Cancel </v-btn>
              <v-btn
                dark
                color="primary"
                @click="
                  showLinkMenu = false;
                  setLink(inputURL);
                "
                :disabled="disabled"
              >
                Set URL
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="disabled"
            dark
            color="#286054"
            v-bind="attrs"
            v-on="on"
            class="pr-2"
          >
            <v-icon small>{{ mdiPlus }}</v-icon>
            Ticket Data
            <v-icon class="ml-2">{{ mdiChevronDown }}</v-icon>
          </v-btn>
        </template>

        <v-list
          class="pa-0 ma-0 overflow-y-auto cursor-pointer"
          max-height="60vh"
        >
          <v-list-item @click="insertTicketLink()" class="text-body-2">
            Link to Ticket
          </v-list-item>
          <v-list-item
            v-if="!isPlainText"
            @click="insertField('printable_text')"
            class="text-body-2"
          >
            Printable Text
          </v-list-item>
          <v-list-item @click="insertTicketXml()" class="text-body-2">
            Ticket XML
          </v-list-item>

          <v-divider></v-divider>
          <div v-for="f of ticketDetailsFields" :key="f.value">
            <v-list-item @click="insertField(f.value)" class="text-body-2">
              {{ f.label }}
            </v-list-item>
          </div>
        </v-list>
      </v-menu>
    </section>

    <editor-content :editor="editor" class="border" />

    <p class="caption red--text" v-if="Array.isArray(errors)">
      {{ errors[0] }}
    </p>
  </section>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import {
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiLink,
  mdiChevronDown,
  mdiPlus,
} from "@mdi/js";
import ticketDetailsFields from "@/constants/ticketDetailsFields";
import ParamPlaceholder from "@/helpers/ParamPlaceholder";

const fontFamilyOptions = [
  {
    value: "Cedarville Cursive",
    label: "Cedarville Cursive",
  },
  {
    value: "Comic Neue",
    label: "Comic Neue",
  },
  {
    value: "Open Sans",
    label: "Open Sans",
  },
  {
    value: "PT Serif",
    label: "PT Serif",
  },
  {
    value: "roboto",
    label: "Roboto",
  },
  {
    value: "Space Mono",
    label: "Space Mono",
  },
];

export default {
  name: "SimpleTemplateEditor",
  components: {
    EditorContent,
  },
  props: {
    item: Object,
    errors: Array,
    valid: Boolean,
    validated: Boolean,
    value: String,
    assignTo: String,
    showToolbarButtons: {
      type: Boolean,
      default: true,
    },
    isPlainText: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      editor: undefined,
      mdiFormatBold,
      mdiFormatItalic,
      mdiFormatUnderline,
      mdiFormatListBulleted,
      mdiFormatListNumbered,
      mdiLink,
      mdiPlus,
      mdiChevronDown,
      showUrlEditorDialog: false,
      showLinkMenu: false,
      inputURL: "",
      ticketDetailsFields,
      fontFamilySelection: "Open Sans",
      fontFamilyOptions,
    };
  },
  computed: {
    fontFamilyValue() {
      if (!this.editor.getAttributes("textStyle").fontFamily) {
        return this.fontFamilySelection;
      }
      return this.editor.getAttributes("textStyle").fontFamily;
    },
  },
  watch: {
    disabled: {
      immediate: true,
      handler(disabled) {
        this.editor?.setOptions?.({ editable: !disabled });
      },
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [StarterKit, Image, Underline, Link, ParamPlaceholder],
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
    this.editor?.setOptions?.({ editable: !this.disabled });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    onFontFamilyChange(fontFamily) {
      this.fontFamilySelection = fontFamily;
      this.editor.chain().focus().setFontFamily(this.fontFamilySelection).run();
    },
    getLink() {
      if (this.editor.isActive("link")) {
        this.inputURL = this.editor.getAttributes("link").href;
      } else {
        this.inputURL = "";
      }
    },
    setLink(url) {
      this.showUrlEditorDialog = false;
      if (url === null) {
        return;
      }

      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    insertContent() {
      let placeholder;

      if (placeholder) {
        this.editor
          .chain()
          .focus()
          .insertContent([
            {
              type: "templatePlaceholder",
              attrs: {
                placeholder,
              },
            },
          ])
          .run();
      }
    },
    insertTicketLink() {
      this.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: "param",
            attrs: {
              param: "ticket_link",
            },
          },
        ])
        .run();
    },
    insertTicketXml() {
      this.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: "param",
            attrs: {
              param: "ticket_xml",
            },
          },
        ])
        .run();
    },
    insertField(param) {
      this.editor
        .chain()
        .focus()
        .insertContent([
          {
            type: "param",
            attrs: {
              param,
            },
          },
        ])
        .run();
    },
  },
};
</script>

<style lang="scss" scoped>
/* Basic editor styles */

.template-editor::v-deep .ProseMirror {
  height: 200px;
  overflow-y: auto;
  margin: 1rem 0;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

/* Table-specific styling */
.template-editor::v-deep .ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.template-editor::v-deep .tableWrapper {
  overflow-x: auto;
}

.template-editor::v-deep .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>

<style scoped>
.border {
  border: 1px solid #d8d8d8;
  padding: 0 5px;
}

.template-editor >>> .ProseMirror:focus {
  outline: none;
}

.v-btn--round {
  border-radius: unset !important;
}

.is-active {
  background: gainsboro;
}
</style>
