export default [
  { label: "Legal Date", value: "legal_date" },
  { label: "Boring", value: "boring" },
  { label: "Caller Name", value: "caller" },
  { label: "Caller Phone", value: "caller_phone" },
  { label: "Caller Email", value: "caller_email" },
  { label: "Contact Name", value: "contact_name" },
  { label: "Contact Phone", value: "contact_phone" },
  { label: "Ticket", value: "ticket" },
  { label: "Street Address", value: "street_address" },
  { label: "City or Place", value: "place" },
  { label: "Meet Requested", value: "meet" },
  { label: "Location", value: "location" },
  { label: "Remarks", value: "remarks" },
  { label: "Emergency", value: "is_emergency" },
  { label: "Rush", value: "is_rush" },
  { label: "Ticket Type", value: "ticket_type" },
  { label: "Work Date", value: "work_date" },
  { label: "Work Type", value: "work_type" },
  { label: "Company Name", value: "company" },
  { label: "State", value: "state" },
  { label: "Meet Date", value: "meet_date" },
];
