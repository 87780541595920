var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "section",
        {
          staticClass:
            "d-flex justify-space-between align-center py-1 flex-wrap",
        },
        [
          _vm.workflowSettingBeingEdited.trigger !== _vm.TRIGGER.TICKET_ASSIGNED
            ? _c(
                "section",
                [
                  !_vm.workflowSettingBeingEdited.filter
                    .locate_request_account_region_id
                    ? _c("v-checkbox", {
                        staticClass: "py-1 my-0",
                        attrs: {
                          label: "Assign Ticket",
                          color: "primary",
                          "hide-details": "",
                          disabled: !_vm.canManage811Codes,
                        },
                        on: { change: _vm.onChange, input: _vm.onChange },
                        model: {
                          value:
                            _vm.workflowSettingBeingEdited.actions.assign_ticket
                              .enabled,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.workflowSettingBeingEdited.actions
                                .assign_ticket,
                              "enabled",
                              $$v
                            )
                          },
                          expression:
                            "workflowSettingBeingEdited.actions.assign_ticket.enabled",
                        },
                      })
                    : _c(
                        "div",
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                disabled: Boolean(
                                  _vm.workflowSettingBeingEdited.filter
                                    .locate_request_account_region_id
                                ),
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.mdiMinusBox) + " ")]
                          ),
                          _c(
                            "span",
                            { staticClass: "ml-2 v-label theme--light" },
                            [_vm._v("Assign Ticket")]
                          ),
                        ],
                        1
                      ),
                  _vm.workflowSettingBeingEdited.filter
                    .locate_request_account_region_id
                    ? _c(
                        "div",
                        {
                          staticClass: "caption font-weight-medium ml-8 mt-n2",
                        },
                        [_vm._v(" Assignment already set based on area ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          !_vm.workflowSettingBeingEdited.filter
            .locate_request_account_region_id &&
          _vm.workflowSettingBeingEdited.actions.assign_ticket.enabled
            ? _c("validation-provider", {
                staticClass: "py-1",
                attrs: { name: "Assigned To", rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-autocomplete", {
                            staticClass: "py-0 my-0 drop-down",
                            attrs: {
                              label: "Assigned To",
                              "error-messages": errors,
                              success: valid,
                              items: _vm.assignToUserChoices,
                              "item-value": "value",
                              "item-text": "label",
                              color: "primary",
                              "hide-details": "auto",
                              disabled: Boolean(
                                _vm.workflowSettingBeingEdited.filter
                                  .locate_request_account_region_id ||
                                  !_vm.canManage811Codes
                              ),
                            },
                            on: { change: _vm.onChange },
                            model: {
                              value:
                                _vm.workflowSettingBeingEdited.actions
                                  .assign_ticket.assign_to_user_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workflowSettingBeingEdited.actions
                                    .assign_ticket,
                                  "assign_to_user_id",
                                  $$v
                                )
                              },
                              expression:
                                "\n          workflowSettingBeingEdited.actions.assign_ticket.assign_to_user_id\n        ",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3693059457
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "section",
        {
          staticClass:
            "d-flex justify-space-between align-center py-1 flex-wrap",
        },
        [
          _c("v-checkbox", {
            staticClass: "py-1 my-0",
            attrs: {
              label: "Send Email Notification",
              color: "primary",
              "hide-details": "",
              disabled: !_vm.canManage811Codes,
            },
            on: { input: _vm.onChange, change: _vm.onChange },
            model: {
              value: _vm.workflowSettingBeingEdited.actions.send_email.enabled,
              callback: function ($$v) {
                _vm.$set(
                  _vm.workflowSettingBeingEdited.actions.send_email,
                  "enabled",
                  $$v
                )
              },
              expression:
                "workflowSettingBeingEdited.actions.send_email.enabled",
            },
          }),
          _vm.workflowSettingBeingEdited.actions.send_email.enabled
            ? _c("validation-provider", {
                staticClass: "py-1",
                attrs: { name: "Send To", rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-autocomplete", {
                            staticClass: "py-0 my-0 drop-down",
                            attrs: {
                              label: "Send To",
                              "error-messages": errors,
                              success: valid,
                              items: _vm.sendToUserChoicesEmail,
                              "item-value": "value",
                              "item-text": "label",
                              color: "primary",
                              "hide-details": "auto",
                              multiple: "",
                              disabled: !_vm.canManage811Codes,
                            },
                            on: { change: _vm.onChange },
                            model: {
                              value:
                                _vm.workflowSettingBeingEdited.actions
                                  .send_email.email_user_ids,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workflowSettingBeingEdited.actions
                                    .send_email,
                                  "email_user_ids",
                                  $$v
                                )
                              },
                              expression:
                                "workflowSettingBeingEdited.actions.send_email.email_user_ids",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  273751296
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.workflowSettingBeingEdited.actions.send_email.enabled
        ? _c("SimpleTemplateEditor", {
            staticClass: "my-2",
            attrs: { disabled: !_vm.canManage811Codes },
            on: { input: _vm.onChange },
            model: {
              value: _vm.workflowSettingBeingEdited.actions.send_email.message,
              callback: function ($$v) {
                _vm.$set(
                  _vm.workflowSettingBeingEdited.actions.send_email,
                  "message",
                  $$v
                )
              },
              expression:
                "workflowSettingBeingEdited.actions.send_email.message",
            },
          })
        : _vm._e(),
      _c(
        "section",
        {
          staticClass:
            "d-flex justify-space-between align-center flex-wrap py-1",
        },
        [
          _c("v-checkbox", {
            staticClass: "py-1 my-0",
            attrs: {
              label: "Send SMS Notification",
              color: "primary",
              "hide-details": "",
              disabled: !_vm.canManage811Codes,
            },
            on: { input: _vm.onChange, change: _vm.onChange },
            model: {
              value: _vm.workflowSettingBeingEdited.actions.send_sms.enabled,
              callback: function ($$v) {
                _vm.$set(
                  _vm.workflowSettingBeingEdited.actions.send_sms,
                  "enabled",
                  $$v
                )
              },
              expression: "workflowSettingBeingEdited.actions.send_sms.enabled",
            },
          }),
          _vm.workflowSettingBeingEdited.actions.send_sms.enabled
            ? _c("validation-provider", {
                attrs: { name: "Send To", rules: "required" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ errors, valid }) {
                        return [
                          _c("v-autocomplete", {
                            staticClass: "py-0 my-0 drop-down",
                            attrs: {
                              label: "Send To",
                              "error-messages": errors,
                              success: valid,
                              items: _vm.sendToUserChoicesSMS,
                              "item-value": "value",
                              "item-text": "label",
                              color: "primary",
                              "hide-details": "auto",
                              multiple: "",
                              disabled: !_vm.canManage811Codes,
                            },
                            on: { change: _vm.onChange },
                            model: {
                              value:
                                _vm.workflowSettingBeingEdited.actions.send_sms
                                  .sms_user_ids,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.workflowSettingBeingEdited.actions
                                    .send_sms,
                                  "sms_user_ids",
                                  $$v
                                )
                              },
                              expression:
                                "workflowSettingBeingEdited.actions.send_sms.sms_user_ids",
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3979144385
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.workflowSettingBeingEdited.actions.send_sms.enabled
        ? _c("SimpleTemplateEditor", {
            staticClass: "my-2",
            attrs: {
              showToolbarButtons: false,
              isPlainText: "",
              disabled: !_vm.canManage811Codes,
            },
            on: { input: _vm.onChange },
            model: {
              value: _vm.workflowSettingBeingEdited.actions.send_sms.message,
              callback: function ($$v) {
                _vm.$set(
                  _vm.workflowSettingBeingEdited.actions.send_sms,
                  "message",
                  $$v
                )
              },
              expression: "workflowSettingBeingEdited.actions.send_sms.message",
            },
          })
        : _vm._e(),
      _c(
        "section",
        {
          staticClass:
            "d-flex justify-space-between align-center flex-wrap py-1",
        },
        [
          _c("v-checkbox", {
            staticClass: "py-1 my-0",
            attrs: {
              label: "Close Ticket",
              color: "primary",
              "hide-details": "",
              disabled: !_vm.canManage811Codes,
            },
            on: { input: _vm.onChange, change: _vm.onChange },
            model: {
              value:
                _vm.workflowSettingBeingEdited.actions.close_ticket.enabled,
              callback: function ($$v) {
                _vm.$set(
                  _vm.workflowSettingBeingEdited.actions.close_ticket,
                  "enabled",
                  $$v
                )
              },
              expression:
                "workflowSettingBeingEdited.actions.close_ticket.enabled",
            },
          }),
          _vm.workflowSettingBeingEdited.actions.close_ticket.enabled
            ? _c("div", [
                _vm._v(
                  " Closes all tickets that match the criteria above. It is strongly recommended that you send an email notification to someone who will review them on a regular basis and verify that the closed tickets are correct. "
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }