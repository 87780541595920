var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showPositiveResponseDialog,
        "max-width": "400px",
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "py-2 font-weight-regular" }, [
            _vm._v(" Positive Response "),
          ]),
          _c("v-card-text", [
            _vm._v(
              " When this UtiliBot is enabled, a positive response is sent to the one-call center after the ticket is cleared. "
            ),
          ]),
          _c(
            "v-card-actions",
            {
              staticClass: "d-flex justify-end py-1 px-3",
              on: {
                click: function ($event) {
                  return _vm.$emit("close-positive-response-dialog")
                },
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular",
                  attrs: { text: "", color: "primary" },
                },
                [_vm._v("Close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }