var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "m-2", staticStyle: { "background-color": "#fafafa" } },
    [
      _c("validation-observer", { ref: "edit811AccountWorkflowsForm" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c(
              "v-toolbar",
              {
                staticClass: "elevation-0",
                attrs: { dark: "", color: "primary" },
              },
              [
                _c("v-toolbar-title", [_vm._v(" Workflows ")]),
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { icon: "", dark: "" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("edit-811-workflows-form-close")
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("mdi-close")])],
                  1
                ),
              ],
              1
            ),
            _c(
              "v-card-text",
              {
                staticClass: "px-8 py-2 overflow-y-auto",
                style: {
                  height: _vm.$vuetify.breakpoint.xsOnly
                    ? "calc(100vh - 120px)"
                    : "65vh",
                },
              },
              [
                _c(
                  "section",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          text: "",
                          color: "primary",
                          disabled: !_vm.canManage811Codes,
                        },
                        on: { click: _vm.addWorkflowSetting },
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { color: "primary", small: "" } },
                          [_vm._v(_vm._s(_vm.mdiPlus))]
                        ),
                        _vm._v(" Workflow "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.workflowSettings.length === 0
                  ? _c(
                      "section",
                      { staticClass: "d-flex justify-center align-center" },
                      [_vm._v(" No Workflows added yet. ")]
                    )
                  : _c(
                      "section",
                      [
                        _c(
                          "v-expansion-panels",
                          {
                            staticClass: "w-100",
                            attrs: { multiple: "" },
                            model: {
                              value: _vm.openWorkflowSettings,
                              callback: function ($$v) {
                                _vm.openWorkflowSettings = $$v
                              },
                              expression: "openWorkflowSettings",
                            },
                          },
                          [
                            _c(
                              "draggable",
                              {
                                staticClass: "w-100",
                                attrs: {
                                  list: _vm.workflowSettings,
                                  group: "workflowSettings",
                                  handle: ".section-handle",
                                },
                              },
                              _vm._l(
                                _vm.workflowSettings,
                                function (workflowSetting, index) {
                                  return _c("EditWorkflowForm", {
                                    key: workflowSetting.id,
                                    staticClass: "w-100 my-0",
                                    class: { "border-top": index > 0 },
                                    attrs: {
                                      users: _vm.users,
                                      ticketTypes: _vm.ticketTypes,
                                      ticketPriorities: _vm.ticketPriorities,
                                      locateRequestAccountRegions:
                                        _vm.locateRequestAccountRegions,
                                    },
                                    on: {
                                      "delete-workflow": function ($event) {
                                        return _vm.deleteWorkflow(index)
                                      },
                                    },
                                    model: {
                                      value: _vm.workflowSettings[index],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.workflowSettings,
                                          index,
                                          $$v
                                        )
                                      },
                                      expression: "workflowSettings[index]",
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            ),
            _c(
              "v-card-actions",
              { staticClass: "d-flex justify-end pa-3" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      type: "submit",
                      disabled: !_vm.canManage811Codes,
                    },
                  },
                  [_vm._v(" Save Workflows ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }