<template>
  <div>
    <v-card class="m-2" style="background-color: #f1f2f1">
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title>
            Ticket Assignment Areas -
            {{ selected811Account.label }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('edit-ticket-areas-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card class="m-3 elevation-0" style="background-color: #f1f2f1">
          <v-card-text
            :style="{
              height: $vuetify.breakpoint.xsOnly
                ? `${contentHeight}px`
                : 'auto',
              'overflow-y': 'auto',
              'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
            }"
          >
            <div>
              Tickets received in these areas will be auto-assigned to the user
              specified.
            </div>
            <div class="d-flex justify-end">
              <v-btn
                text
                color="primary"
                @click="showAddTicketAreaDialog = true"
                :disabled="!canManage811Codes"
              >
                + ADD
              </v-btn>
            </div>
            <validation-observer ref="editTicketAreasForm">
              <v-data-table
                :headers="headers"
                :items="ticketAreaAssignmentsBeingEdited"
                item-key="locate_request_account_region_id"
                disable-pagination
                hide-default-footer
                class="py-0 mt-2 mb-0"
                single-select
              >
                <template v-slot:body="{ items }">
                  <draggable :list="items" tag="tbody" @end="onDragEnd(items)">
                    <tr
                      v-for="item of items"
                      :key="item.locate_request_account_region_id"
                    >
                      <td class="px-1 mx-0">
                        <v-icon>{{ mdiDragVertical }}</v-icon>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Region Name"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.regionName"
                            label="Region Name"
                            hide-details="auto"
                            :error-messages="errors"
                            :success="valid"
                            color="primary"
                            :disabled="!canManage811Codes"
                          />
                        </validation-provider>
                      </td>
                      <td>
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Assigned To"
                          rules="required"
                        >
                          <v-select
                            v-model="item.assignedTo"
                            :items="users"
                            :item-text="getUserName"
                            item-value="user_id"
                            :error-messages="errors"
                            :success="valid"
                            color="primary"
                            hide-details="auto"
                            :disabled="!canManage811Codes"
                          ></v-select>
                        </validation-provider>
                      </td>
                      <td>
                        <v-switch
                          v-model="item.isActive"
                          :disabled="!canManage811Codes"
                        ></v-switch>
                      </td>
                    </tr>
                  </draggable>
                </template>
              </v-data-table>
            </validation-observer>
          </v-card-text>
        </v-card>
      </form>

      <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
        <v-btn
          color="primary"
          @click="saveTicketAreaSettings"
          :disabled="!canManage811Codes"
        >
          Save Ticket Areas
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="showAddTicketAreaDialog"
      max-width="400px"
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <AddTicketAreaForm
        @add-ticket-area-form-close="showAddTicketAreaDialog = false"
        @add-ticket-area-form-submitted="showAddTicketAreaDialog = false"
        @added-ticket-area-form="
          showAddTicketAreaDialog = false;
          getTicketAreasAndAssignments();
        "
        v-if="showAddTicketAreaDialog"
        :users="users"
        :selected811Account="selected811Account"
      />
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import draggable from "vuedraggable";
import { mdiDragVertical } from "@mdi/js";
import { cloneDeep } from "lodash";
import AddTicketAreaForm from "@/components/accounts-811/AddTicketAreaForm.vue";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

const headers = [
  {
    text: "",
    align: "start",
    sortable: false,
    value: "",
  },
  {
    text: "Region Name",
    align: "start",
    sortable: false,
    value: "regionName",
  },
  {
    text: "Assigned To",
    align: "start",
    sortable: false,
    value: "assignedTo",
  },
  {
    text: "Active",
    align: "start",
    sortable: false,
    value: "isActive",
  },
];

export default {
  name: "EditTicketAreasForm",
  mixins: [contentHeightMixin, permissionsMixin],
  components: { draggable, AddTicketAreaForm },
  data() {
    return {
      providerAccountId: undefined,
      ticketAreaData: [],
      headers,
      mdiDragVertical,
      ticketAreaAssignmentsBeingEdited: [],
      showAddTicketAreaDialog: false,
    };
  },
  computed: {
    ticketAreaAssignments() {
      const ticketAreaTable = this.ticketAreaData
        .map((s) => ({
          regionName: s.region_name,
          assignedTo: s.user_id,
          isActive: s.is_active,
          locateRequestAccountRegionId: s.locate_request_account_region_id,
          priorityOrder: s.priority_order,
        }))
        .sort((a, b) => +a.priorityOrder - +b.priorityOrder);

      return ticketAreaTable;
    },
  },
  props: {
    selected811Account: Object,
    users: Array,
  },
  methods: {
    onDragEnd(reorderedItems) {
      const ticketAreaAssignments = [...reorderedItems];
      for (const [index] of ticketAreaAssignments.entries()) {
        ticketAreaAssignments[index].priorityOrder = index + 1;
      }
      this.ticketAreaAssignments = ticketAreaAssignments;
    },
    getUserName(item) {
      const user = this.users?.find((u) => u.user_id === item.user_id);
      if (user) {
        const { f_name: fName, l_name: lName } = user;
        return `${fName} ${lName}`;
      }
      return "";
    },
    async getTicketAreasAndAssignments() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/locate_request_account_regions`, {
        params: { locate_request_provider_account_id: this.providerAccountId },
      });

      this.ticketAreaData = results;
    },
    async saveTicketAreaSettings() {
      const success = await this.$refs.editTicketAreasForm.validate();
      if (!success) {
        return;
      }
      const mappedAssignments = this.ticketAreaAssignments.map((a) => {
        const {
          locateRequestAccountRegionId,
          regionName,
          isActive,
          priorityOrder,
          assignedTo,
        } = a;
        return {
          locate_request_account_region_id: locateRequestAccountRegionId,
          region_name: regionName,
          is_active: isActive,
          priority_order: priorityOrder + 100,
          user_id: assignedTo,
        };
      });
      await axios.put(`${APIURL}/locate_request_account_regions`, {
        locate_request_account_regions: mappedAssignments,
      });

      const mappedAssignmentsWithRightOrder =
        this.ticketAreaAssignmentsBeingEdited.map((a) => {
          const {
            locateRequestAccountRegionId,
            regionName,
            isActive,
            priorityOrder,
            assignedTo,
          } = a;
          return {
            locate_request_account_region_id: locateRequestAccountRegionId,
            region_name: regionName,
            is_active: isActive,
            priority_order: priorityOrder + 1,
            user_id: assignedTo,
          };
        });
      await axios.put(`${APIURL}/locate_request_account_regions`, {
        locate_request_account_regions: mappedAssignmentsWithRightOrder,
      });
      this.$emit("edit-ticket-areas-form-submitted");
    },
  },
  async beforeMount() {
    this.providerAccountId =
      this.selected811Account.locate_request_provider_account_id;
    this.getTicketAreasAndAssignments();
  },
  watch: {
    ticketAreaAssignments: {
      deep: true,
      handler(val) {
        this.ticketAreaAssignmentsBeingEdited = cloneDeep(val);
      },
    },
  },
};
</script>
