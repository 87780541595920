<template>
  <validation-observer ref="addTicketAreasForm" slim v-slot="{ valid }">
    <v-card class="m-2" style="background-color: #f1f2f1">
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title>Add Ticket Assignment Area</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('add-ticket-area-form-close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card class="m-3 elevation-0" style="background-color: #f1f2f1">
          <v-card-text
            :style="{
              height: $vuetify.breakpoint.xsOnly
                ? `${contentHeight}px`
                : 'auto',
              'overflow-y': 'auto',
              'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
            }"
          >
            <v-card style="background-color: #fafafa">
              <v-card-text>
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Region Name"
                  rules="required"
                >
                  <v-text-field
                    v-model="regionName"
                    label="Region Name"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                  />
                </validation-provider>

                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Assigned To"
                  rules="required"
                >
                  <v-select
                    autocomplete="off"
                    v-model="userId"
                    label="Assigned To"
                    :items="users"
                    :item-text="getUserName"
                    item-value="user_id"
                    :error-messages="errors"
                    :success="valid"
                    color="primary"
                    hide-details="auto"
                    class="mt-4"
                  ></v-select>
                </validation-provider>

                <p class="caption pt-5 my-0">
                  Upload GeoJSON of Assignment Area
                </p>
                <file-pond
                  name="file"
                  ref="file"
                  label-idle="Upload GeoJSON file here..."
                  :allow-multiple="false"
                  @addfile="onAddFile"
                  @removefile="onRemoveFile"
                  :files="geoJsonFile"
                />
                <validation-provider
                  v-slot="{ errors, valid, pristine }"
                  name="GeoJSON"
                  ref="geoJsonInput"
                  :rules="{ required: true }"
                  class="my-0 py-0"
                >
                  <input
                    type="text"
                    v-model="geoJsonString"
                    style="display: none"
                  />
                  <p
                    class="caption my-0 py-0"
                    :style="{
                      color: valid || pristine ? undefined : '#ff5252',
                    }"
                  >
                    <template
                      v-if="!pristine && !valid && Array.isArray(errors)"
                    >
                      {{ errors.join(". ") }}
                    </template>
                    <template v-else>&nbsp;</template>
                  </p>
                </validation-provider>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </form>

      <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
        <v-btn
          color="primary"
          @click="submit"
          :disabled="!valid || geoJsonString === JSON.stringify({})"
        >
          Save Ticket Area
        </v-btn>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import contentHeightMixin from "@/mixins/contentHeightMixin";
import vueFilePond from "vue-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import axios from "axios";
import "filepond/dist/filepond.min.css";

const FilePond = vueFilePond(FilePondPluginFileEncode);
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "AddTicketAreaForm",
  mixins: [contentHeightMixin],
  data() {
    return {
      regionName: undefined,
      userId: undefined,
      geoJsonFile: undefined,
      geoJsonString: JSON.stringify({}),
    };
  },
  computed: {},
  components: {
    FilePond,
  },
  props: {
    selected811Account: Object,
    users: Array,
  },
  methods: {
    getUserName(item) {
      const user = this.users?.find((u) => u.user_id === item.user_id);
      if (user) {
        const { f_name: fName, l_name: lName } = user;
        return `${fName} ${lName}`;
      }
      return "";
    },
    async onAddFile() {
      const [file] = this.$refs.file.getFiles();
      const text = await file.file.text();
      this.geoJsonString = text;
      await this.$refs.geoJsonInput.reset();
      this.$refs.geoJsonInput.setFlags({ pristine: false });
      await this.$refs.geoJsonInput.validate();
    },
    onRemoveFile() {
      this.$refs.geoJsonInput.reset();
      this.geoJsonString = JSON.stringify({});
    },
    async submit() {
      const success = await this.$refs.addTicketAreasForm.validate();
      if (!success) {
        return;
      }
      const { regionName, userId, geoJsonString, selected811Account } = this;
      const {
        locate_request_provider_account_id: locateRequestProviderAccountId,
      } = selected811Account;
      const {
        data: { results: ticketAreaData },
      } = await axios.get(`${APIURL}/locate_request_account_regions`, {
        params: {
          locate_request_provider_account_id: locateRequestProviderAccountId,
        },
      });
      const priorityOrders = ticketAreaData.map((t) => t.priority_order);
      const maxPriorityOrder = Math.max(...priorityOrders);
      const geoJson = JSON.parse(geoJsonString);

      await axios.post(`${APIURL}/locate_request_account_regions`, {
        region_name: regionName,
        user_id: userId,
        geo_json: geoJson?.features?.[0]?.geometry ?? {},
        locate_request_provider_account_id: locateRequestProviderAccountId,
        priority_order: priorityOrders.length >= 1 ? maxPriorityOrder + 1 : 1,
      });
      this.$emit("added-ticket-area-form");
    },
  },
};
</script>
