<template>
  <v-card class="m-2" style="background-color: #f1f2f1">
    <validation-observer ref="emergencyNotificationSettingsForm">
      <form @submit.prevent="submit">
        <v-toolbar dark color="primary" class="elevation-0" ref="toolbar">
          <v-toolbar-title
            >Edit Notifications -
            {{ selected811Account.label }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="$emit('edit-emergency-notification-settings-form-close')"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card class="m-3 elevation-0">
          <v-card-text
            :style="{
              'background-color': '#f1f2f1',
              height: $vuetify.breakpoint.xsOnly
                ? `${contentHeight}px`
                : 'auto',
              'overflow-y': 'auto',
              'max-height': $vuetify.breakpoint.xsOnly ? undefined : '60vh',
            }"
          >
            <v-simple-table
              fixed-header
              :height="
                $vuetify.breakpoint.xsOnly ? `${contentHeight * 0.9}px` : '55vh'
              "
            >
              <thead>
                <tr>
                  <th class="text-left">Notification:</th>
                  <th class="text-center" colspan="2">Emergency</th>
                  <th class="text-center" colspan="2">Rush</th>
                  <th class="text-center" colspan="2" v-if="isCo811">Late</th>
                </tr>
                <tr>
                  <th>User</th>
                  <th>Email?</th>
                  <th>Text?</th>
                  <th>Email?</th>
                  <th>Text?</th>
                  <th v-if="isCo811">Email?</th>
                  <th v-if="isCo811">Text?</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="setting of notificationSettings"
                  :key="setting.userId"
                >
                  <td>{{ setting.fName }} {{ setting.lName }}</td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-checkbox
                        v-model="setting.emergencyEmail"
                        :disabled="!canManage811Codes"
                      ></v-checkbox>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-tooltip
                        bottom
                        v-if="!setting.smsNotification"
                        open-on-click
                        :open-on-hover="false"
                        v-model="showEmergencyTooltips[setting.userId]"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-simple-checkbox
                            class="d-flex justify-center"
                            v-ripple
                            v-model="setting.emergencyText"
                            disabled
                            v-bind="attrs"
                            @click="
                              closeTooltips();
                              on.click($event);
                            "
                          ></v-simple-checkbox>
                        </template>

                        <span class="caption">No Phone Number Added </span>
                      </v-tooltip>
                      <v-checkbox
                        v-else
                        v-model="setting.emergencyText"
                        :disabled="
                          !setting.smsNotification || !canManage811Codes
                        "
                      ></v-checkbox>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-checkbox
                        v-model="setting.rushEmail"
                        :disabled="!canManage811Codes"
                      ></v-checkbox>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex justify-center">
                      <v-tooltip
                        bottom
                        v-if="!setting.smsNotification"
                        open-on-click
                        :open-on-hover="false"
                        v-model="showRushTooltips[setting.userId]"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-simple-checkbox
                            class="d-flex justify-center"
                            v-ripple
                            v-model="setting.rushText"
                            disabled
                            v-bind="attrs"
                            @click="
                              closeTooltips();
                              on.click($event);
                            "
                          ></v-simple-checkbox>
                        </template>

                        <span class="caption">No Phone Number Added </span>
                      </v-tooltip>
                      <v-checkbox
                        v-else
                        v-model="setting.rushText"
                        :disabled="
                          !setting.smsNotification || !canManage811Codes
                        "
                      ></v-checkbox>
                    </div>
                  </td>
                  <td v-if="isCo811">
                    <div class="d-flex justify-center">
                      <v-checkbox v-model="setting.lateEmail"></v-checkbox>
                    </div>
                  </td>
                  <td v-if="isCo811">
                    <div class="d-flex justify-center">
                      <v-tooltip
                        bottom
                        v-if="!setting.smsNotification"
                        open-on-click
                        :open-on-hover="false"
                        v-model="showLateTooltips[setting.userId]"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-simple-checkbox
                            class="d-flex justify-center"
                            v-ripple
                            v-model="setting.lateText"
                            disabled
                            v-bind="attrs"
                            @click="
                              closeTooltips();
                              on.click($event);
                            "
                          ></v-simple-checkbox>
                        </template>

                        <span class="caption"> No Phone Number Added </span>
                      </v-tooltip>
                      <v-checkbox
                        v-else
                        v-model="setting.lateText"
                        :disabled="!canManage811Codes"
                      ></v-checkbox>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </form>
    </validation-observer>

    <v-card-actions class="d-flex justify-end pa-5" ref="cardAction">
      <v-btn
        color="primary"
        :dark="canManage811Codes"
        @click="saveNotificationSettings"
        :disabled="!canManage811Codes"
      >
        Save Notifications
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
import { cloneDeep } from "lodash";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import permissionsMixin from "@/mixins/permissionsMixin";

const APIURL = process.env.VUE_APP_API_URL;

const account = {
  locate_request_provider_account_id: "",
  locate_request_provider_id: "",
  name: "",
  out_of_region_action: "",
  out_of_region_email_settings: JSON.stringify({
    body: "",
    testEmail: "",
    cc: "",
    fromName: "",
    bcc: "",
    replyTo: "",
    subject: "",
  }),
  positive_response_password: "",
  positive_response_username: "",
  send_positive_response: false,
  time_zone: "",
  user_group_id: "",
  provider_code: "",
};

export default {
  name: "EditEmergencyNotificationSettingsForm",
  mixins: [contentHeightMixin, permissionsMixin],
  data() {
    return {
      account,
      notificationSettings: [],
      notificationSettingsAllAccounts: [],
      showEmergencyTooltips: {},
      showRushTooltips: {},
      showLateTooltips: {},
    };
  },
  computed: {
    isCo811() {
      return (
        this.account.locate_request_provider_id ===
        this.co811LocateRequestProviderId
      );
    },
    co811LocateRequestProviderId() {
      return this.locateRequestProviders.find((l) => l.code === "CO811")
        ?.locate_request_provider_id;
    },
  },
  props: {
    selected811Account: Object,
    locateRequestProviders: Array,
    users: Array,
  },
  methods: {
    watchAndAutoCloseTooltips() {
      this.$watch(
        (vm) => [
          vm.showEmergencyTooltips,
          vm.showRushTooltips,
          vm.showLateTooltips,
        ],
        () => {
          const emergencyTooltips = Object.values(
            this.showEmergencyTooltips
          ).some(Boolean);
          const rushTooltips = Object.values(this.showRushTooltips).some(
            Boolean
          );
          const lateTooltips = Object.values(this.showLateTooltips).some(
            Boolean
          );
          const tooltipsOpen =
            emergencyTooltips || rushTooltips || lateTooltips;
          if (tooltipsOpen) {
            setTimeout(() => {
              this.closeTooltips();
            }, 2000);
          }
        },
        {
          deep: true,
        }
      );
    },
    closeTooltips() {
      this.showEmergencyTooltips = {};
      this.showRushTooltips = {};
      this.showLateTooltips = {};
    },
    populateEmergencySettings() {
      this.notificationSettings = this.users.map((u) => {
        const {
          f_name: fName,
          l_name: lName,
          user_id: userId,
          sms_notification: smsNotification,
        } = u;
        return { fName, lName, userId, smsNotification };
      });

      const {
        email_user_ids: emailUserIds = [],
        sms_user_ids: smsUserIds = [],
      } = this.account.emergency_notification_settings;
      for (const userId of emailUserIds) {
        const userIndex = this.notificationSettings.findIndex(
          (s) => s.userId === userId
        );
        this.$set(this.notificationSettings, userIndex, {
          ...this.notificationSettings[userIndex],
          emergencyEmail: true,
        });
      }

      for (const userId of smsUserIds) {
        const userIndex = this.notificationSettings.findIndex(
          (s) => s.userId === userId
        );
        this.$set(this.notificationSettings, userIndex, {
          ...this.notificationSettings[userIndex],
          emergencyText: true,
        });
      }
    },
    populateRushSettings() {
      const account = this.notificationSettingsAllAccounts.find(
        (a) =>
          a.locate_request_provider_account_id ===
          this.selected811Account?.locate_request_provider_account_id
      );
      const {
        email_user_ids: emailUserIds = [],
        sms_user_ids: smsUserIds = [],
      } = account.rush_notification_settings ?? {};
      for (const userId of emailUserIds) {
        const userIndex = this.notificationSettings.findIndex(
          (s) => s.userId === userId
        );
        this.$set(this.notificationSettings, userIndex, {
          ...this.notificationSettings[userIndex],
          rushEmail: true,
        });
      }

      for (const userId of smsUserIds) {
        const userIndex = this.notificationSettings.findIndex(
          (s) => s.userId === userId
        );
        this.$set(this.notificationSettings, userIndex, {
          ...this.notificationSettings[userIndex],
          rushText: true,
        });
      }
    },
    populateLateSettings() {
      const account = this.notificationSettingsAllAccounts.find(
        (a) =>
          a.locate_request_provider_account_id ===
          this.selected811Account?.locate_request_provider_account_id
      );
      const {
        email_user_ids: emailUserIds = [],
        sms_user_ids: smsUserIds = [],
      } = account.late_notification_settings ?? {};
      for (const userId of emailUserIds) {
        const userIndex = this.notificationSettings.findIndex(
          (s) => s.userId === userId
        );
        this.$set(this.notificationSettings, userIndex, {
          ...this.notificationSettings[userIndex],
          lateEmail: true,
        });
      }

      for (const userId of smsUserIds) {
        const userIndex = this.notificationSettings.findIndex(
          (s) => s.userId === userId
        );
        this.$set(this.notificationSettings, userIndex, {
          ...this.notificationSettings[userIndex],
          lateText: true,
        });
      }
    },
    async getUserNotificationSettingsAllAccounts() {
      const {
        data: { results },
      } = await axios.get(`${APIURL}/notifications/provider_accounts/admin`);
      this.notificationSettingsAllAccounts = results;
    },
    async saveNotificationSettings() {
      const emergEmailUserIds = this.notificationSettings
        .filter((u) => u.emergencyEmail)
        .map((u) => u.userId);

      const emergSmsUserIds = this.notificationSettings
        .filter((u) => u.emergencyText)
        .map((u) => u.userId);

      const rushEmailUserIds = this.notificationSettings
        .filter((u) => u.rushEmail)
        .map((u) => u.userId);

      const rushSmsUserIds = this.notificationSettings
        .filter((u) => u.rushText)
        .map((u) => u.userId);

      const lateEmailUserIds = this.notificationSettings
        .filter((u) => u.lateEmail)
        .map((u) => u.userId);

      const lateSmsUserIds = this.notificationSettings
        .filter((u) => u.lateText)
        .map((u) => u.userId);
      const payload = {
        emergency_notification_settings: {
          email_user_ids: emergEmailUserIds,
          sms_user_ids: emergSmsUserIds,
        },
        rush_notification_settings: {
          email_user_ids: rushEmailUserIds,
          sms_user_ids: rushSmsUserIds,
        },
        late_notification_settings: {
          email_user_ids: lateEmailUserIds,
          sms_user_ids: lateSmsUserIds,
        },
      };
      await axios.put(
        `${APIURL}/notifications/provider_account/${this.selected811Account?.locate_request_provider_account_id}`,
        payload
      );
      this.$emit("edit-emergency-notification-settings-form-submitted");
    },
  },
  async beforeMount() {
    await this.getUserNotificationSettingsAllAccounts();
    this.account = cloneDeep(this.selected811Account);
    this.populateEmergencySettings();
    this.populateRushSettings();
    this.populateLateSettings();
    this.watchAndAutoCloseTooltips();
  },
};
</script>
