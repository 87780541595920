<template>
  <v-dialog
    :value="showAutoCancelTicketsDialog"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="py-2 font-weight-regular">
        Auto-Close Cancel Tickets
      </v-card-title>
      <v-card-text>
        When this UtiliBot is enabled and a cancel ticket is received, the
        cancel ticket and all related tickets are closed and are counted as "not
        marked" (e.g. no paint on the ground).
      </v-card-text>
      <v-card-actions
        class="d-flex justify-end py-1 px-3"
        @click="$emit('close-auto-cancel-ticket-dialog')"
      >
        <v-btn text color="primary" class="font-weight-regular">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AutoCancelTicketsDialog",
  props: {
    showAutoCancelTicketsDialog: Boolean,
  },
};
</script>
