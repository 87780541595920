<template>
  <v-dialog
    :value="showPositiveResponseDialog"
    max-width="400px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-card-title class="py-2 font-weight-regular">
        Positive Response
      </v-card-title>
      <v-card-text>
        When this UtiliBot is enabled, a positive response is sent to the
        one-call center after the ticket is cleared.
      </v-card-text>
      <v-card-actions
        class="d-flex justify-end py-1 px-3"
        @click="$emit('close-positive-response-dialog')"
      >
        <v-btn text color="primary" class="font-weight-regular">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AutoCancelTicketsDialog",
  props: {
    showPositiveResponseDialog: Boolean,
  },
};
</script>
