var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { staticClass: "m-2", staticStyle: { "background-color": "#f1f2f1" } },
        [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-toolbar",
                {
                  ref: "toolbar",
                  staticClass: "elevation-0",
                  attrs: { dark: "", color: "primary" },
                },
                [
                  _c("v-toolbar-title", [
                    _vm._v(
                      " Ticket Assignment Areas - " +
                        _vm._s(_vm.selected811Account.label) +
                        " "
                    ),
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("edit-ticket-areas-form-close")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "m-3 elevation-0",
                  staticStyle: { "background-color": "#f1f2f1" },
                },
                [
                  _c(
                    "v-card-text",
                    {
                      style: {
                        height: _vm.$vuetify.breakpoint.xsOnly
                          ? `${_vm.contentHeight}px`
                          : "auto",
                        "overflow-y": "auto",
                        "max-height": _vm.$vuetify.breakpoint.xsOnly
                          ? undefined
                          : "60vh",
                      },
                    },
                    [
                      _c("div", [
                        _vm._v(
                          " Tickets received in these areas will be auto-assigned to the user specified. "
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "primary",
                                disabled: !_vm.canManage811Codes,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showAddTicketAreaDialog = true
                                },
                              },
                            },
                            [_vm._v(" + ADD ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "validation-observer",
                        { ref: "editTicketAreasForm" },
                        [
                          _c("v-data-table", {
                            staticClass: "py-0 mt-2 mb-0",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.ticketAreaAssignmentsBeingEdited,
                              "item-key": "locate_request_account_region_id",
                              "disable-pagination": "",
                              "hide-default-footer": "",
                              "single-select": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "body",
                                fn: function ({ items }) {
                                  return [
                                    _c(
                                      "draggable",
                                      {
                                        attrs: { list: items, tag: "tbody" },
                                        on: {
                                          end: function ($event) {
                                            return _vm.onDragEnd(items)
                                          },
                                        },
                                      },
                                      _vm._l(items, function (item) {
                                        return _c(
                                          "tr",
                                          {
                                            key: item.locate_request_account_region_id,
                                          },
                                          [
                                            _c(
                                              "td",
                                              { staticClass: "px-1 mx-0" },
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiDragVertical)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Region Name",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                label:
                                                                  "Region Name",
                                                                "hide-details":
                                                                  "auto",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                color:
                                                                  "primary",
                                                                disabled:
                                                                  !_vm.canManage811Codes,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.regionName,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "regionName",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.regionName",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Assigned To",
                                                    rules: "required",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                          valid,
                                                        }) {
                                                          return [
                                                            _c("v-select", {
                                                              attrs: {
                                                                autocomplete:
                                                                  "off",
                                                                items:
                                                                  _vm.users,
                                                                "item-text":
                                                                  _vm.getUserName,
                                                                "item-value":
                                                                  "user_id",
                                                                "error-messages":
                                                                  errors,
                                                                success: valid,
                                                                color:
                                                                  "primary",
                                                                "hide-details":
                                                                  "auto",
                                                                disabled:
                                                                  !_vm.canManage811Codes,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.assignedTo,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "assignedTo",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.assignedTo",
                                                              },
                                                            }),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "td",
                                              [
                                                _c("v-switch", {
                                                  attrs: {
                                                    disabled:
                                                      !_vm.canManage811Codes,
                                                  },
                                                  model: {
                                                    value: item.isActive,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "isActive",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.isActive",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }),
                                      0
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { ref: "cardAction", staticClass: "d-flex justify-end pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: !_vm.canManage811Codes },
                  on: { click: _vm.saveTicketAreaSettings },
                },
                [_vm._v(" Save Ticket Areas ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "400px",
            persistent: "",
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
          },
          model: {
            value: _vm.showAddTicketAreaDialog,
            callback: function ($$v) {
              _vm.showAddTicketAreaDialog = $$v
            },
            expression: "showAddTicketAreaDialog",
          },
        },
        [
          _vm.showAddTicketAreaDialog
            ? _c("AddTicketAreaForm", {
                attrs: {
                  users: _vm.users,
                  selected811Account: _vm.selected811Account,
                },
                on: {
                  "add-ticket-area-form-close": function ($event) {
                    _vm.showAddTicketAreaDialog = false
                  },
                  "add-ticket-area-form-submitted": function ($event) {
                    _vm.showAddTicketAreaDialog = false
                  },
                  "added-ticket-area-form": function ($event) {
                    _vm.showAddTicketAreaDialog = false
                    _vm.getTicketAreasAndAssignments()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }