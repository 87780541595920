export default [
  { id: 1, facilityType: "Facility Owner" },
  { id: 9, facilityType: "Gas" },
  { id: 10, facilityType: "Electric" },
  { id: 11, facilityType: "Gas & Electric" },
  { id: 12, facilityType: "Communications" },
  { id: 13, facilityType: "Cable TV" },
  { id: 14, facilityType: "Phone" },
  { id: 15, facilityType: "Fiber" },
  { id: 16, facilityType: "Sewer" },
  { id: 17, facilityType: "Water" },
  { id: 18, facilityType: "Sewer & Water" },
  { id: 19, facilityType: "Steam" },
  { id: 20, facilityType: "Pipeline" },
  { id: 21, facilityType: "Petroleum" },
  { id: 22, facilityType: "Sanitary Sewer" },
  { id: 23, facilityType: "Storm Sewer" },
];
